<template>
  <div v-if="permission.access">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <b-card>
          <div class="row">
            <div class="col-md-4">
              <b-form-group label="Select Role" label-for="role_id">
                <validation-provider
                  #default="{ errors }"
                  name="role_id"
                  rules="required"
                >
                  <v-select
                    id="role_id"
                    v-model="form.role_id"
                    placeholder="Select Role"
                    :options="roles"
                    label="name"
                    :reduce="(option) => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Name" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    placeholder="Enter Your Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Email" label-for="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Password" label-for="a-password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-form-input
                    id="a-password"
                    v-model="form.password"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Confirm Password" label-for="ac-password">
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="ac-password"
                    v-model="form.password_confirmation"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <b-form-checkbox name="status" v-model="form.status" switch inline>
            Status
          </b-form-checkbox>
        </b-card>
        <div class="d-flex justify-content-end mb-5">
          <b-button type="submit" variant="primary" @click="validationForm">
            Save
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { required, email, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      min,
      confirmed,
      snowOption: {
        minHeight: 200,
      },
      form: {
        role_id: "",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        status: true,
      },
      modules: {},
      roles: [],
      permission: {
        access: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getRole();
  },
  methods: {
    async getRole() {
      const roles = await this.callApi("post", "/app/user/role/get");
      if (roles.status == 200) {
        this.roles = roles.data.roles;
      }
    },
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "role.create") {
          this.permission.access = true;
        }
      });
    },

    add() {
      axios
        .post("/app/user/store", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.$router.push("/users");
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name) {
              this.e(e.response.data.errors.name[0]);
            }
            if (e.response.data.errors.email) {
              this.e(e.response.data.errors.email[0]);
            }
            if (e.response.data.errors.password) {
              this.e(e.response.data.errors.password[0]);
            }
            if (e.response.data.errors.password_confirmation) {
              this.e(e.response.data.errors.password_confirmation[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
